<template>
  <div class="bigbox">
      <div class="zhfwtop">
        <div class="indeximgtopcontent">
           <div class="indexlogo"></div>
      <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span >产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <li  @click="go('/cpjjfa/zhcg')">智慧采购</li>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li  @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li  @click="go('/yhal')"><span class="active2">用户案例</span></li>
            <li  @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
      </div>
        </div>

      <div class="zhfwbutton"></div>
      </div>
      <!-- 介绍 -->
      <div class="jkjtindece">
        <div class="jkjtindececontent">
          <div class="jkjtindececontenttitle">各项战略交接项目通过为集团管理系统管理事务的信息化，以流程控制发展集团为管理系统提供法律服务平台化的手段，以统一的方式为集团管理系统的法律事务管理，以统一的方式为集团管理事务的法律事务管理。标准和规范，各项业务流程化管理，提高效率和管理效果，提高流程法务、和集团在线业务工作的综合工作质量平台的质量。</div>
          <div class="jkjt_png1"></div>
          <div class="jkjt_png2"></div>
          <div class="jkjtindececontenttitle2">交控资本投资管理有限公司股权管理系统旨在通过对交控资本投资项目管理的信息化，实现投资项目在线化、信息化管理，提供快捷档案信息查询功能，提高流程效率和管理效能。</div>
          <div class="jkjt_png3"></div>
          <div class="jkjt_png4"></div>
        </div>
      </div>
      <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../../components/TabarBottom.vue'
  export default {
    components:{
      TabarBottom
    },
      methods:{
        go(path){
          this.$router.push({
            path:path
          })
        }
      }
  }
</script>

<style lang="scss" scoped>
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
//
.jkjtindece{
height: 2875px;
background: #fff;
.jkjtindececontent{
  width: 1200px;
  height: inherit;
  margin: 0 auto;
  padding-top: 60px;
  .jkjtindececontenttitle{
    text-indent: 2rem;
    width: 1200px;
    height: 80px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #282A2E;
  }
  .jkjt_png1{
    width: 1200px;
    height: 438px;
    background: url('../../.././assets/jkjt_png1.png') no-repeat center 0;
    margin-top: 40px;
  }
  .jkjt_png2{
    width: 1200px;
    height: 675px;
    background: url('../../.././assets/jkjt_png2.png') no-repeat center 0;
    margin-top: 40px;
  }
  .jkjtindececontenttitle2{
    width: 1200px;
    height: 49px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #282A2E;
    text-indent: 2rem;
    margin-top: 40px;
  }
  .jkjt_png3{
    width: 1200px;
    height: 620px;
    background: url('../../.././assets/jkjt_png3.png') no-repeat center 0;
    margin-top: 40px;
  }
  .jkjt_png4{
    width: 1200px;
    height: 675px;
    background: url('../../.././assets/jkjt_png4.png') no-repeat center 0;
    margin-top: 40px;
    margin-bottom: 88px;
  }
}
}
//
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../../.././assets/yhal_jkjt.png') no-repeat center 0;
  margin: 0 auto;
  padding-top: 34px;
  .indexlogo{
  width: 220px;
  height: 52px;
  background: url('../../.././assets/logo.png') no-repeat center 0;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
  left: 16px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
